import { useEffect } from 'react';

const GetQueryParams = () => {
  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined' && typeof window.sessionStorage !== 'undefined') {
      if (document.referrer) {//!Taking care of the referrer to trace the redirect source
        window.sessionStorage.setItem('referrer', document.referrer);
      }
      window.sessionStorage.setItem('location', window.location.href);

      const queryParams = new URLSearchParams(window.location.search);
      const utmSource = queryParams.get('utm_source') || '';
      const utmMedium = queryParams.get('utm_medium') || '';
      const utmTerm = queryParams.get('utm_term') || '';
      const utmCampaign = queryParams.get('utm_campaign') || '';
      
      if (utmSource) {
        window.sessionStorage.setItem('utm_source', utmSource);
      }
      if (utmTerm) {
        window.sessionStorage.setItem('utm_term', utmTerm);
      }
      if (utmMedium) {
        window.sessionStorage.setItem('utm_medium', utmMedium);
      }
      if (utmCampaign) {
        window.sessionStorage.setItem('utm_campaign', utmCampaign);
      }
    }
  }, []);	
  return <></>;
};
export default GetQueryParams;