'use client';

import React, { useState } from 'react';
import { useServerInsertedHTML } from 'next/navigation';
import { ServerStyleSheet, StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import propTypes from 'prop-types';

//* For framer-motion to work with StyledComponents and our isPropValid usage, we need to use this registry.
const propsRegistry = (tag, { render }) => {
  const isMotionComponent = render?.name === 'MotionComponent';
  if (isMotionComponent) return true;
  return isPropValid(tag)
};


const StyledComponentsRegistry = ({ children }) => {
  const [styledComponentsStyleSheet] = useState(() => new ServerStyleSheet());

  useServerInsertedHTML(() => {
    const styles = styledComponentsStyleSheet.getStyleElement();
    styledComponentsStyleSheet.instance.clearTag();
    return <>{styles}</>;
  });

  if (typeof window !== 'undefined') return <StyleSheetManager shouldForwardProp={propsRegistry}>{children}</StyleSheetManager>;

  return (
    <StyleSheetManager sheet={styledComponentsStyleSheet.instance} shouldForwardProp={isPropValid}>
      {children}
    </StyleSheetManager>
  );
};

StyledComponentsRegistry.propTypes = {
  children: propTypes.node
};

export default StyledComponentsRegistry;