import { createGlobalStyle } from 'styled-components';

const StyledSite = createGlobalStyle`
    html,
    body {
      padding: 0;
      margin: 0;
      direction: rtl;
      text-align: right;
      font-size: 16px;
      font-family: ${({ theme }) => theme.fonts?.ploni};
      font-weight: 300;

      ${({ theme }) => theme.bps?.mobile} {
        max-width: ${({ theme }) => theme.maxWidth?.mobile}px;
        margin: 0 auto;
        font-size: 13px;
        line-height: 1.4;
      }
      
      .shrink {
        ${({ theme }) => theme.mixins?.shrink};
      }

      h1, h2, h3 {
        font-weight: bold;
        margin: 0;
      }

      .h1, h1 {
        font-size: 131px;
        ${({ theme }) => theme.bps?.mobile} {
          font-size: 40px;
        }
      }

      .h1, h1 {
        font-family: ${({ theme }) => theme.fonts?.rosenberg};
      }

      .h1-en, h1.h1-en {
        font-family: ${({ theme }) => theme.fonts?.smartNext};
        font-size: 131px;

        ${({ theme }) => theme.bps?.mobile} {
          font-size: 40px;
        }
      }

      .h2, h2 {
        font-size: 124px;
        font-family: ${({ theme }) => theme.fonts?.ploni};

        ${({ theme }) => theme.bps?.mobile} {
          font-size: 40px;
        }
      }

      .h2-en, h2.h2-en {
        font-family: ${({ theme }) => theme.fonts?.smartSans};
        font-size: 124px;
        font-weight: 700;

        ${({ theme }) => theme.bps?.mobile} {
          font-size: 40px;
        }
      }

      .h3, h3 {
        font-size: 40px;
        font-family: ${({ theme }) => theme.fonts?.ploni};
      }

      .h3-en, h3.h3-en {
        font-family: ${({theme}) => theme.fonts?.smartSans};
      }

      h4, .h4 {
        font-family: ${({theme}) => theme.fonts?.ploni};
        font-size: 25px;
        font-weight: bold;
      }

      h5, .h5 {
        font-family: ${({theme}) => theme.fonts?.ploni};
        font-size: 21px;
        font-weight: 400;
      }

      h6, .h6 {
        font-family: ${({theme}) => theme.fonts?.ploni};
        font-size: 20px;
        font-weight: bold;
      }

      p, span, a, button {
        font-family: ${({ theme }) => theme.fonts?.ploni};
        font-size: 16px;

        ${({ theme }) => theme.bps?.mobile} {
          font-size: 13px;
          line-height: 1.4;
        }
      }

      a, button {
        text-decoration: none;
        cursor: pointer;
      }

      * {
        box-sizing: border-box;
      }

      &.ReactModal__Body--open {
        overflow: hidden;
      }

      .ReactModal__Overlay {
        z-index: 10;
      }

      .header-menu-modal {
        &.ReactModal__Overlay {
          background-color: rgba(0,0,0,0.6);
        }

        &.ReactModal__Content {
          width: 0;
          visibility: hidden;
          transition: all 500ms ease-in-out, visibility 1s linear;
        }

        &.ReactModal__Content--after-open{
          width: 100vw;
          visibility: visible;
        }

        &.ReactModal__Content--before-close{
          width: 0;
        }
      }
    }
  ${({ theme }) => theme.bps?.mobile} {
		#u1st {
			#u1stLogoContainer {
				position: fixed;
				top:85% !important;
				left:-1px !important;
        right:unset !important;
			}
      .u1st-hideAccBtn {
        display: none;
      }
		}
  }
  
  .grecaptcha-badge { 
    visibility: hidden;
  } 

`;

export default StyledSite;
