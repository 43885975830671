'use client';

import { Suspense, useState } from 'react';
import propTypes from 'prop-types';

//* Progress Bar
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';

//* Styles
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'styles/GlobalStyle';
import theme from 'styles/theme';
import GetQueryParams from 'core/functions/GetQueryParams';

import { datadogRum } from '@datadog/browser-rum';
import {ContactUsProvider} from 'providers/useContactUs';

if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod') {
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'smart',
    env: process.env.NEXT_PUBLIC_ENVIRONMENT,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.NEXT_PUBLIC_DD_VERSION,
    sessionSampleRate: 20,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  });
}

function App({ children }) {
  const [contactUsFilled, setContactUsFilled] = useState(false);
  const data = {
    contactUsData: {
      setContactUsFilled,
      contactUsFilled
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <GetQueryParams />
      <ContactUsProvider value={data}>{children}</ContactUsProvider>
      <Suspense>
        <ProgressBar height="4px" color="#29d" options={{ showSpinner: false }} shallowRouting />
      </Suspense>
    </ThemeProvider>
  );
}

App.propTypes = {
  children: propTypes.node,
  cookieSettings: propTypes.object
};

export default App;
